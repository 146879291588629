export const bids = [
    {
        title: "Amazing digital art",
        price: "2.45 ETH",
        highestBid: "0.001 ETH",
        counter: "3 in stock",
        bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
        image: "/images/content/card-pic-1.jpg",
        image2x: "/images/content/card-pic-1@2x.jpg",
        category: "green",
        categoryText: "purchasing !",
        url: "/item",
        users: [
            {
                avatar: "/images/content/avatar-1.jpg",
            },
            {
                avatar: "/images/content/avatar-3.jpg",
            },
            {
                avatar: "/images/content/avatar-4.jpg",
            },
        ],
    },
    {
        title: "Ribbon Hunter",
        price: "2.45 ETH",
        highestBid: "0.001 ETH",
        counter: "3 in stock",
        bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
        image: "/images/content/card-pic-2.jpg",
        image2x: "/images/content/card-pic-2@2x.jpg",
        category: "green",
        categoryText: "purchasing !",
        url: "/item",
        users: [
            {
                avatar: "/images/content/avatar-1.jpg",
            },
            {
                avatar: "/images/content/avatar-4.jpg",
            },
        ],
    },
    {
        title: "Amazing digital art",
        price: "2.45 ETH",
        highestBid: "0.001 ETH",
        counter: "3 in stock",
        bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
        image: "/images/content/card-pic-3.jpg",
        image2x: "/images/content/card-pic-3@2x.jpg",
        category: "green",
        categoryText: "purchasing !",
        url: "/item",
        users: [
            {
                avatar: "/images/content/avatar-1.jpg",
            },
            {
                avatar: "/images/content/avatar-3.jpg",
            },
            {
                avatar: "/images/content/avatar-4.jpg",
            },
        ],
    },
    {
        title: "Amazing digital art",
        price: "2.45 ETH",
        highestBid: "0.001 ETH",
        counter: "3 in stock",
        bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
        image: "/images/content/card-pic-4.jpg",
        image2x: "/images/content/card-pic-4@2x.jpg",
        category: "green",
        categoryText: "purchasing !",
        url: "/item",
        users: [
            {
                avatar: "/images/content/avatar-1.jpg",
            },
            {
                avatar: "/images/content/avatar-4.jpg",
            },
        ],
    },
    {
        title: "Ribbon Hunter",
        price: "2.45 ETH",
        highestBid: "0.001 ETH",
        counter: "3 in stock",
        bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
        image: "/images/content/card-pic-5.jpg",
        image2x: "/images/content/card-pic-5@2x.jpg",
        category: "green",
        categoryText: "purchasing !",
        url: "/item",
        users: [
            {
                avatar: "/images/content/avatar-1.jpg",
            },
            {
                avatar: "/images/content/avatar-3.jpg",
            },
            {
                avatar: "/images/content/avatar-4.jpg",
            },
        ],
    },
    {
        title: "Amazing digital art",
        price: "2.45 ETH",
        highestBid: "0.001 ETH",
        counter: "3 in stock",
        bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
        image: "/images/content/card-pic-6.jpg",
        image2x: "/images/content/card-pic-6@2x.jpg",
        category: "green",
        categoryText: "purchasing !",
        url: "/item",
        users: [
            {
                avatar: "/images/content/avatar-1.jpg",
            },
            {
                avatar: "/images/content/avatar-4.jpg",
            },
        ],
    },
    {
        title: "Amazing digital art",
        price: "2.45 ETH",
        highestBid: "0.001 ETH",
        counter: "3 in stock",
        bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
        image: "/images/content/card-pic-7.jpg",
        image2x: "/images/content/card-pic-7@2x.jpg",
        category: "green",
        categoryText: "purchasing !",
        url: "/item",
        users: [
            {
                avatar: "/images/content/avatar-1.jpg",
            },
            {
                avatar: "/images/content/avatar-4.jpg",
            },
        ],
    },
    {
        title: "Amazing digital art",
        price: "2.45 ETH",
        highestBid: "0.001 ETH",
        counter: "3 in stock",
        bid: 'New bid <span role="img" aria-label="fire">🔥</span>',
        image: "/images/content/card-pic-1.jpg",
        image2x: "/images/content/card-pic-1@2x.jpg",
        category: "green",
        categoryText: "purchasing !",
        url: "/item",
        users: [
            {
                avatar: "/images/content/avatar-1.jpg",
            },
            {
                avatar: "/images/content/avatar-4.jpg",
            },
        ],
    },
];
